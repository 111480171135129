import { Dictionary } from '@onaio/utils';
import { AnyAction } from 'redux';

export const actionComponentColumnEdit = (data: Dictionary): AnyAction => {
  return {
    type: 'POST_COMPONENT_COLUMN_EDIT',
    data,
  };
};

export const actionComponentGroupByEdit = (data: Dictionary): AnyAction => {
  return {
    type: 'POST_COMPONENT_GROUP_BY_EDIT',
    data,
  };
};

export const actionComponentTableSortEdit = (data: Dictionary): AnyAction => {
  return {
    type: 'POST_COMPONENT_TABLE_SORT_EDIT',
    data,
  };
};
