import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getComponentById, getComponentSource } from '../../../../reducers/selectors/post';
import { NumberComponent } from '../../../../configs/component-types';
import { useFetchComponentData } from '../../hooks';
import { formatValue } from '../../helpers';
import { Dictionary } from '@onaio/utils';
import { getCardSource } from '../../Chart/helpers/helpers';
import { Chart, Axis, Tooltip, Legend, Interval, Annotation, Coord } from 'bizcharts';

export interface DoughnutPropertiesProps {
  componentIndex: number; // index of component
  componentId: string; // id of component
  dataRow?: Dictionary;
}

const makeGetComponentByIndex = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

const Donut: React.FC<DoughnutPropertiesProps> = (props: DoughnutPropertiesProps) => {
  const { componentId, dataRow } = props;

  // memoize selectors
  const selectComponentByIndex = useMemo(makeGetComponentByIndex, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const component = useSelector((state) => {
    /* @ts-ignore */
    const number = selectComponentByIndex(state, { componentId });
    if (!number) {
      return undefined;
    }
    return number as NumberComponent;
  });

  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));

  const [, data] = useFetchComponentData(componentId);
  let dataFiltered = data;

  let dataRowComparable =
    dataRow?.[`${getCardSource(dataRow, component?.context)}.${component?.context}`];

  // check if dataRow comes from map popup

  if (component?.context && dataRow?.[component?.context]) {
    dataRowComparable = dataRow?.[component.context];
  }

  if (dataRow && component?.properties && component?.properties[0]) {
    dataFiltered = data.filter(
      (item) => item[`${component?.cube}.${component?.context}`] === dataRowComparable
    );
  }

  const getColor = (type: string | null) => {
    if (component?.colors && component?.colorField) {
      const color = component.colors.filter(
        (item) => item.property === dataFiltered[0][`${component?.cube}.${component?.colorField}`]
      );
      if ((color[0] && type === 'propertyValue') || (color[0] && type === null)) {
        return String(color[0].value);
      }
      if (color[0] && type === 'negativeValue') {
        return '#f9f9f9';
      }
      if (color[0] && type === 'aboveMax') {
        return String(color[0].value);
      }
    }
    if (component?.color) {
      if (type === 'propertyValue' || type === null) {
        return component.color;
      }
      if (type === 'negativeValue') {
        return '#f9f9f9';
      }
      if (type === 'aboveMax') {
        return component.color;
      }
    }
    return '#f9f9f9';
  };
  return (
    <Chart
      padding={[0, 0, 0, 0]}
      height={component?.height || 90}
      data={[
        {
          value: Number(dataFiltered[0]?.[`${component?.cube}.${component?.properties[0].value}`]),
          type: 'propertyValue',
        },
        {
          value: Number(
            100 - dataFiltered[0]?.[`${component?.cube}.${component?.properties[0].value}`]
          ),
          type:
            dataFiltered[0]?.[`${component?.cube}.${component?.properties[0].value}`] > 100
              ? 'aboveMax'
              : 'negativeValue',
        },
      ]}
      animate={false}
      autoFit
    >
      <Coord type="theta" innerRadius={0.7} />
      {component?.showValue && (
        <Annotation.Text
          position={['50%', '50%']}
          content={formatValue(
            component?.properties[0],
            dataFiltered[0]?.[`${component?.cube}.${component?.properties[0].value}`],
            source
          )}
          style={{
            fontSize: 16,
            fontWeight: 700,
            fill: getColor(null),
            textAlign: 'center',
          }}
        />
      )}
      <Axis visible={false} />
      <Legend visible={false} />
      <Tooltip visible={false} />
      <Interval
        adjust="stack"
        style={{
          fillOpacity: 1,
        }}
        color={['type', getColor]}
        position="value"
      />
    </Chart>
  );
};

export { Donut };
