import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { message } from 'antd';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../../configs/env';
import { Dictionary } from '@onaio/utils';
import { sourceAsyncOperationsJobStatusTypes } from '../../../../configs/component-types';
import {
  startAsycSourceUpdateFromFile,
  startAsycJobForCreatingDatabaseSourceType,
  startAsycJobForCreatingSourceRefreshSchedule,
  startAsycJobForUpdatingSourceRefreshSchedule,
  startAsycJobForUpdatingGeometry,
  startAsycJobForCreatingGeometry,
  getSource,
  startAsycGoogleSheetSourceCreation,
  startAsycPostgresSQLSourceCreation,
  startAsycS3ParquetSourceCreation,
  createParquetSourceFromFile,
  createGeoParquetSourceFromFile,
  createGeoParquetSourceFromUrl,
  createSourceGeometryFromPMTilesFile,
  createSourceGeometryFromPMTilesServer,
  updateS3UserSecretsForS3ParquetSource,
  updateSourceCubeSchema,
  updateSourceDimensions,
  updateSourceMeasures,
  deleteSourceCubeDimensions,
  deleteSourceCubeMeasures,
} from '../../helpers/helpers';

export const checkAsyncTaskProgress = async (
  jobId: string,
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
  setAsyncTaskProgressData: (progress: Dictionary) => void,
  asyncTaskName: string,
  setLoading: (loadingState: boolean) => void
): Promise<void> => {
  socket?.on(
    jobId,
    (data: {
      progress: {
        stage: sourceAsyncOperationsJobStatusTypes;
        data: Dictionary[];
        errors: Dictionary[];
      };
    }) => {
      switch (data?.progress?.stage) {
        case sourceAsyncOperationsJobStatusTypes.DONE:
          setAsyncTaskProgressData(data?.progress);
          socket?.disconnect();
          break;
        default:
          if (data?.progress?.errors.length !== 0) {
            const errorMessage = data?.progress?.errors[0]?.message;
            if (errorMessage) {
              message.error(`${errorMessage}`, 4);
            } else {
              message.error(`${asyncTaskName} failed`, 4);
            }
            setAsyncTaskProgressData({});
            setLoading(false);
            socket?.disconnect();
          } else {
            setAsyncTaskProgressData(data?.progress);
          }
          break;
      }
    }
  );
};

export const triggerAsycSourceOperation = ({
  asynFunc,
  asynFuncArgs,
  setAsyncTaskProgressData,
  asyncTaskName,
  setLoading,
  webSocketUrl,
}: {
  asynFunc:
    | typeof startAsycJobForCreatingDatabaseSourceType
    | typeof startAsycSourceUpdateFromFile
    | typeof startAsycJobForCreatingSourceRefreshSchedule
    | typeof startAsycJobForUpdatingSourceRefreshSchedule
    | typeof startAsycJobForUpdatingGeometry
    | typeof startAsycJobForCreatingGeometry
    | typeof startAsycGoogleSheetSourceCreation
    | typeof startAsycPostgresSQLSourceCreation
    | typeof startAsycS3ParquetSourceCreation
    | typeof createParquetSourceFromFile
    | typeof createGeoParquetSourceFromFile
    | typeof createGeoParquetSourceFromUrl
    | typeof createSourceGeometryFromPMTilesFile
    | typeof createSourceGeometryFromPMTilesServer
    | typeof updateS3UserSecretsForS3ParquetSource
    | typeof updateSourceCubeSchema
    | typeof updateSourceDimensions
    | typeof updateSourceMeasures
    | typeof deleteSourceCubeDimensions
    | typeof deleteSourceCubeMeasures;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asynFuncArgs: any;
  setAsyncTaskProgressData: (progress: Dictionary) => void;
  asyncTaskName: string;
  setLoading: (loadingState: boolean) => void;
  webSocketUrl: string;
}): void => {
  const socket = io(webSocketUrl, {
    path: '/v1/socket.io',
    withCredentials: true,
  });
  socket.on('connect', async () => {
    const source = await getSource({
      sourceId: asynFuncArgs?.sourceId,
    });
    let processing = source?.config?.processing;
    let jobId = source?.config?.job_uuid;
    // Get geometry processing data
    if (asynFuncArgs?.geometryId) {
      const geometry = source?.geometries.find(
        (geometry: Dictionary) => geometry?.id === asynFuncArgs?.geometryId
      );
      processing = geometry?.processing;
      jobId = geometry?.job_uuid;
    }

    if (processing === true) {
      await checkAsyncTaskProgress(
        jobId,
        socket,
        setAsyncTaskProgressData,
        asyncTaskName,
        setLoading
      );
    } else {
      // start asyc source operation
      asynFunc(asynFuncArgs)
        .then(async (res) => {
          const data = res as Dictionary;
          await checkAsyncTaskProgress(
            data.job_uuid,
            socket,
            setAsyncTaskProgressData,
            asyncTaskName,
            setLoading
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          jobId = undefined;
          message.error(`${asyncTaskName} failed`, 4);
          setAsyncTaskProgressData({});
          setLoading(false);
          socket?.disconnect();
        });
    }
    // eslint-disable-next-line no-console
    console.log(
      `client connected to websock server ${TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL}, socket id: ${socket.id}`
    );
  });
  socket.on('connect_error', () => {
    setTimeout(() => socket.connect(), 5000);
  });
  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.log(
      `client disconnected from websock server ${TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL}`
    );
  });
};
