/**
 * Get table cell color
 *
 * @param {object} column table column
 * @param {number} value cell value
 * @returns {string|null} string if color found, null otherwise
 */
export const getCellColor = (column, value) => {
  let color = null;
  if (column?.colorMode === 'steps' && column.colorSteps?.length > 0) {
    column.colorSteps.every((item, index) => {
      if (Number(value) <= Number(item.value)) {
        color = item.color;
        return false;
      }
      return true;
    });
  }
  if (
    column?.colorMode === 'generatedStepsBrakes' &&
    column.generatedSteps?.length > 0
  ) {
    column.generatedSteps.some((item, index) => {
      const currentStepValue = Number(item.value);
      const nextStepValue = Number(column.generatedSteps[index + 1]?.value);

      // Check if the value falls within the current range
      if (
        Number(value) >= currentStepValue &&
        (isNaN(nextStepValue) || Number(value) < nextStepValue)
      ) {
        color = item.color;
        return true; // Exit the loop when the correct color is found
      }
      return false; // Continue the loop until the right step is found
    });
  }
  if (
    column?.colorMode === 'categories' &&
    column.colorCategories?.length > 0
  ) {
    column.colorCategories.forEach((item) => {
      if (item.value == String(value)) {
        color = item.color;
      } else if (
        column?.showOtherCategories &&
        column?.defaultCategoryColors?.length
      ) {
        color = column?.defaultCategoryColors?.[0]?.color;
      }
    });
  }
  return color;
};
