import { GroupFieldProps } from '../types';
import React from 'react';
import { Row, Col } from 'antd';

export const layout: GroupFieldProps = {
  groups: ['Layout', 'Grid', 'Height', 'Scrolling'],
  styles: ['Width', 'Padding', 'Margin', 'Background', 'Border', 'Shadow', 'Mobile'],
  map: [
    'Layout',
    'Grid',
    'Position',
    'Height',
    'Width',
    'Toggle Visibility',
    'Collapse',
    'Scrolling',
  ],
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Layout',
      placeHolder: 'Layout Title',
    },
    {
      type: 'color',
      label: 'Title color',
      property: 'titleColor',
      group: 'Layout',
      dependsOn: [
        {
          property: 'title',
          value: '*',
        },
      ],
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Layout',
      placeHolder: 'Layout Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Layout',
      placeHolder: 'Layout Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'switch',
      label: 'Use as Reference',
      property: 'useAsReference',
      group: 'Layout',
    },
    {
      type: 'text',
      label: 'ID',
      property: 'id',
      disabled: true,
      group: 'Layout',
      dependsOn: [{ property: 'useAsReference', value: true }],
    },
    {
      type: 'switch',
      label: 'Enable collapse toggle',
      property: 'showDisplayToggle',
      group: 'Collapse',
    },
    {
      type: 'switch',
      property: 'collapsed',
      label: 'Collapsed',
      group: 'Collapse',
      dependsOn: [{ property: 'showDisplayToggle', value: true }],
    },
    {
      type: 'switch',
      label: 'Enable close control',
      property: 'allowClose',
      group: 'Toggle Visibility',
    },
    {
      type: 'switch',
      property: 'hidden',
      label: 'Hidden',
      group: 'Visibility',
    },
    {
      type: 'color',
      label: 'Background',
      property: 'layoutBackgroundColor',
      group: 'Background',
    },
    {
      type: 'color',
      label: 'Header background color',
      property: 'headerBackgroundColor',
      group: 'Background',
      dependsOn: [
        {
          property: 'title',
          value: '*',
        },
      ],
    },
    {
      type: 'slider',
      label: 'Rows',
      property: 'rows',
      min: 1,
      max: 10,
      step: 1,
      group: 'Grid',
      dependsOn: [
        {
          property: 'layoutId',
          value: ['!', 'tabs'],
        },
        {
          property: 'layoutId',
          value: ['!', 'collapse'],
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'gutter',
      label: 'Gutter',
      group: 'Grid',
      placeHolder: '0',
      defaultValue: 15,
      min: 0,
      max: 40,
    },
    {
      type: 'slider',
      property: 'rowMargin',
      label: 'Row margin',
      group: 'Grid',
      placeHolder: '0',
      defaultValue: 15,
      min: 0,
      max: 200,
    },
    {
      type: 'slider',
      property: 'paddingTop',
      label: 'Top',
      group: 'Padding',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'paddingRight',
      label: 'Right',
      group: 'Padding',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'paddingBottom',
      label: 'Bottom',
      group: 'Padding',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'paddingLeft',
      label: 'Left',
      group: 'Padding',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'marginTop',
      label: 'Top',
      group: 'Margin',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'marginRight',
      label: 'Right',
      group: 'Margin',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'marginBottom',
      label: 'Bottom',
      group: 'Margin',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'marginLeft',
      label: 'Left',
      group: 'Margin',
      placeHolder: '0',
    },
    {
      type: 'select',
      property: 'positionVertical',
      label: 'Vertical',
      group: 'Position',
      options: [
        { value: 'top', label: 'Top' },
        { value: 'bottom', label: 'Bottom' },
      ]
    },
    {
      type: 'select',
      property: 'positionHorizontal',
      label: 'Horizontal',
      group: 'Position',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'right', label: 'Right' },
      ],
    },
    {
      type: 'slider',
      property: 'verticalValue',
      label: 'Vertical',
      min: 0,
      max: 200,
      step: 5,
      group: 'Position',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'horizontalValue',
      label: 'Horizontal',
      min: 0,
      max: 1200,
      step: 5,
      group: 'Position',
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'percentWidth',
      label: 'Width',
      group: 'Width',
      min: 5,
      max: 100,
      dependsOn: [{ property: 'widthMetric', value: '%' }],
    },
    {
      type: 'slider',
      property: 'pixelWidth',
      label: 'Width',
      group: 'Width',
      min: 20,
      max: 1200,
      dependsOn: [{ property: 'widthMetric', value: 'px' }],
    },
    {
      type: 'select',
      label: 'Metric',
      property: 'widthMetric',
      group: 'Width',
      options: [
        { value: '%', label: 'Percent' },
        { value: 'px', label: 'Pixels' },
        { value: 'auto', label: 'Auto' },
      ],
    },
    {
      type: 'slider',
      property: 'percentHeight',
      label: 'Height',
      group: 'Height',
      min: 5,
      max: 100,
      placeHolder: '100',
      dependsOn: [{ property: 'heightMetric', value: '%' }],
    },
    {
      type: 'slider',
      property: 'pixelHeight',
      label: 'Height',
      group: 'Height',
      min: 20,
      max: 1200,
      placeHolder: '300',
      dependsOn: [{ property: 'heightMetric', value: 'px' }],
    },
    {
      type: 'select',
      label: 'Metric',
      property: 'heightMetric',
      group: 'Height',
      options: [
        { value: '%', label: 'Percent' },
        { value: 'px', label: 'Pixels' },
        { value: 'auto', label: 'Auto' },
      ],
    },
    {
      type: 'switch',
      property: 'layoutBorder',
      label: 'Enable',
      group: 'Border',
    },
    {
      type: 'slider',
      property: 'borderRadius',
      label: 'Radius',
      group: 'Border',
    },
    {
      type: 'switch',
      property: 'layoutShadow',
      label: 'Enable',
      group: 'Shadow',
    },
    {
      type: 'layout',
      label: 'Columns',
      property: 'layoutId',
      options: [
        {
          value: '24',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={24}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '12-12',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={12}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={12}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '8-8-8',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '6-6-6-6',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '8-16',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={16}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '16-8',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={16}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '18-6',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={18}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '6-18',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={18}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '4-20',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={4}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={20}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '20-4',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={20}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={4}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: 'tabs',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={4}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
                <Col xs={4}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
                <Col xs={4}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
                <Col xs={4}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
              </Row>
              
            </div>
          ),
        },
        {
          value: 'collapse',
          label: (
            <div className="layout--thumb">
              <Row gutter={10}>
                <Col xs={24}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
                <Col xs={24}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
                <Col xs={24}>
                  <div className="thumb-region thumb-region-xxs"></div>
                </Col>
              </Row>
            </div>
          ),
        },
      ],
      group: 'Grid',
    },
    //   collapsible layout settings
    {
      type: 'select',
      label: 'Expand icon position',
      property: 'expandIconPosition',
      group: 'Grid',
      options: [
        {
          value: 'start',
          label: 'Start',
        },
        {
          value: 'end',
          label: 'End',
        },
      ],
      dependsOn: [
        {
          property: 'layoutId',
          value: 'collapse',
        },
      ],
    },

    {
      type: 'switch',
      label: 'Accordion',
      property: 'accordion',
      group: 'Grid',
      dependsOn: [
        {
          property: 'layoutId',
          value: 'collapse',
        },
      ],
    },

    // modal settings

    {
      type: 'switch',
      label: 'Modal Visibility',
      property: 'isModalVisible',
      group: 'Grid',
      dependsOn: [
        {
          property: 'layoutId',
          value: 'modal',
        },
      ],
    },

    // drawer settings

    {
      type: 'switch',
      label: 'Drawer Visibility',
      property: 'isDrawerVisible',
      group: 'Grid',
      dependsOn: [
        {
          property: 'layoutId',
          value: 'drawer',
        },
      ],
    },

    {
      type: 'select',
      label: 'Placement',
      property: 'placement',
      group: 'Grid',
      options: [
        {
          value: 'top',
          label: 'Top',
        },
        {
          value: 'bottom',
          label: 'Bottom',
        },
        {
          value: 'left',
          label: 'Bottom',
        },
        {
          value: 'right',
          label: 'Bottom',
        },
      ],
      dependsOn: [
        {
          property: 'layoutId',
          value: 'draw',
        },
      ],
    },

    // tab settings

    {
      type: 'select',
      label: 'Tabs layout',
      property: 'tabLayout',
      group: 'Grid',
      options: [
        {
          value: 'top',
          label: 'Horizontal',
        },
        {
          value: 'left',
          label: 'Vertical',
        },
      ],
      dependsOn: [
        {
          property: 'layoutId',
          value: 'tabs',
        },
      ],
    },

    {
      type: 'slider',
      label: 'Tabs padding',
      property: 'tabPadding',
      group: 'Grid',
      dependsOn: [
        {
          property: 'layoutId',
          value: 'tabs',
        },
      ],
    },
    {
      type: 'select',
      property: 'mobileGrid',
      label: 'Mobile grid',
      group: 'Mobile',
      placeHolder: 'Collapse',
      options: [
        { value: 'xs', label: 'Retain' },
        { value: 'sm', label: 'Collapse' },
      ],
    },
    {
      type: 'switch',
      label: 'Vertical',
      property: 'yScroll',
      group: 'Scrolling',
    },
    {
      type: 'switch',
      label: 'Horizontal',
      property: 'xScroll',
      group: 'Scrolling',
    },
    {
      type: 'select',
      label: 'Mobile layout height',
      property: 'mobileHeight',
      placeHolder: '50%',
      options: [
        { value: '80', label: '80%' },
        { value: '70', label: '70%' },
        { value: '60', label: '60%' },
        { value: '50', label: '50%' },
        { value: '40', label: '40%' },
        { value: '30', label: '30%' },
        { value: '20', label: '20%' },
      ],
      group: 'Mobile',
    },
    {
      type: 'switch',
      label: 'Hide layout on mobile',
      property: 'hideOnMobile',
      group: 'Mobile',
    },
    {
      type: 'array',
      label: 'Tabs',
      property: 'tabs',
      group: 'Grid',
      groups: ['Tabs'],
      settings: [
        {
          label: 'Tabs',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Tab',
      collapse: false,
      collapseProperty: 'title',
      collapseParent: 'tabs',
      defaultValue: {
        title: 'Tab',
      },
      fields: [
        {
          type: 'text',
          label: 'Tab title',
          property: 'title',
          group: 'Tabs',
          parents: ['tabs'],
        },
      ],
      dependsOn: [
        {
          property: 'layoutId',
          value: 'tabs',
        },
      ],
    },

    {
      type: 'array',
      label: 'Item',
      property: 'collapse',
      group: 'Grid',
      groups: ['Collapses'],
      settings: [
        {
          label: 'Collapses',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Item',
      collapse: false,
      collapseProperty: 'title',
      collapseParent: 'collapse',
      defaultValue: {
        title: 'Item',
      },
      fields: [
        {
          type: 'text',
          label: 'Item title',
          property: 'title',
          group: 'Collapses',
          parents: ['collapse'],
        },
        {
          type: 'switch',
          label: 'Hide arrow',
          property: 'hideArrow',
          group: 'Collapses',
          parents: ['collapse'],
        },
      ],
      dependsOn: [
        {
          property: 'layoutId',
          value: 'collapse',
        },
      ],
    },
  ],
};
