import { useState, useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Route, Redirect } from 'react-router-dom';
import Loader from '../../Post/Loader/Loader'
import FiveHundredError from '../../500'
import AuthRedirect from '../AuthRedirect'
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API } from '../../../configs/env';
import { useDispatch } from 'react-redux';
import { actionUserGet } from '../actions';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [redirectToSetHandlePage, setRedirectToSetHandlePage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      auth.signinRedirect({
        redirect_uri: window.location.href,
      });
      setHasTriedSignin(true);
    });
  }, [auth.events]);

  useEffect(() => {
    if (auth?.error?.message === 'authentication_expired') {
      auth.signinRedirect({
        redirect_uri: window.location.href,
      });
      setHasTriedSignin(true);
    }
  }, [auth.error]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinRedirect({
        redirect_uri: window.location.href,
      });
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    return auth.events.addSilentRenewError((error) => {
      if (error?.message === 'Session not active' || error?.message === 'No matching state found in storage' || error?.message === 'Token is not active') {
        auth.signinRedirect({
          redirect_uri: window.location.href,
        });
        setHasTriedSignin(true);
      }
    });
  }, [auth.events]);

  useEffect(() => {
    if (!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin && redirectToSetHandlePage !== true)) {
      auth.signinRedirect({
        redirect_uri: window.location.href,
      });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    if (auth.isAuthenticated === true && auth.isLoading !== true) {
      const handleAuthCallback = async () => {
        try {
          const userKeycloakResourceExistsService = new AkukoAPIService(IDENTITY_API, `user/${auth?.user?.profile?.email}/keycloak/resources/exists`);
          const response = await userKeycloakResourceExistsService.list();
          if (response?.exists === false) {
            setRedirectToSetHandlePage(true);
          }
          if (response?.exists === true) {
            const userProfileService = new AkukoAPIService(IDENTITY_API, `user/profile`);
            const user = await userProfileService.read(auth?.user?.profile?.sub);
            dispatch(actionUserGet(user));
          }
        } catch (error) {
          console.error('Error handling callback:', error);
          setError(error?.message)
        } finally {
          setLoading(false);
        }
      };

      handleAuthCallback();
    }
  }, [auth.isAuthenticated, auth.isLoading])

  return (
    <>
      {auth.error?.message.includes('No matching state found in storage') && auth.isLoading === false && <AuthRedirect />}
      {redirectToSetHandlePage && <Redirect to="/user/set-handle" />}
      {(error || auth.error) && !loading ? (
        <FiveHundredError errorMessage={error || auth.error?.message} redirectUrl='/feed' />
      ) : loading ? (
        <Loader />
      ) : auth.isAuthenticated ? (
        <Route {...rest} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PrivateRoute;
