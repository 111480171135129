import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router';

interface ErrorInterface {
  titleMessage?: string;
  errorMessage?: string;
  redirectUrl?: string;
}

const FiveHundredError: React.FC = (props: ErrorInterface) => {
  const { titleMessage, errorMessage, redirectUrl } = props;
  const history = useHistory();
  return (
    <Result
      status='500'
      title={titleMessage || '500'}
      subTitle={errorMessage || 'Sorry, something went wrong.'}
      extra={
        <Button
          onClick={() => {
            history.push(redirectUrl || `/`);
          }}
          type='primary'
        >
          Back Home
        </Button>
      }
    />
  );
};

export default FiveHundredError;
