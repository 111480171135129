import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sortColorSteps } from '../utils';
import { abbreviateNumber } from 'js-abbreviation-number';
import { Dictionary } from '@onaio/utils';
import { LegendValueProps } from '../../../../../../configs/types';

export interface LegendOptions {
  startAtZero?: boolean;
  decimalPoint?: number;
  absoluteValue?: boolean;
}

interface QuantityHorizontalLegendProps {
  colorSteps: LegendValueProps[];
  legendOptions?: LegendOptions;
  colorMethod?: string;
  classes?: number | string;
}

const QuantityHorizontalLegend = (
  props: QuantityHorizontalLegendProps
): JSX.Element => {
  const [steps, setSteps] = useState<LegendValueProps[]>([]);
  const { legendOptions, colorMethod, classes } = props;

  const displayValue = (stepsValue:number, legendOptions?: LegendOptions) => {
    let displayValue: string | number = '';

  if (stepsValue !== undefined) {
    const decimalPoint = legendOptions?.decimalPoint ?? 1;
    
    if (stepsValue > 0) {
      displayValue = legendOptions?.absoluteValue
        ? stepsValue
        : abbreviateNumber(stepsValue, decimalPoint);
    } else {
      displayValue = -abbreviateNumber(stepsValue, decimalPoint);
    }
  }
  
  return displayValue;
  }

  useEffect(() => {
    setSteps(sortColorSteps(props.colorSteps));
  }, [props.colorSteps]);

  return (
    <div className='quantity-horizontal-legend'>
      <div className='quantity-horizontal-legend--colors'>
        {steps.map((step, i) => {
          if (i < Number(classes)) {
          const stepsValue =
            colorMethod === 'breaks' ? steps[i + 1]?.value : step.value;
          let colorStyle: Dictionary = {
            background: step.color,
            borderRightStyle: 'none',
          };

          if (i !== 0) {
            colorStyle = {
              ...colorStyle,
              borderLeft: 'none',
            };
          }

          return (
            <Fragment key={i}>
              <div
                className='quantity-horizontal-legend--color'
                style={colorStyle}
              >
                {i < steps.length - 1 && (
                  <span className='quantity-horizontal-legend--value'>
                    {displayValue(stepsValue, legendOptions)}
                  </span>
                )}
                {i < steps.length - 1 &&
                  i == 0 &&
                  legendOptions?.startAtZero && (
                    <span className='quantity-horizontal-legend--start'>
                      {0}
                    </span>
                  )}
              </div>
              {i < steps.length - 1 && (
                <div className='quantity-horizontal-legend--color-transition'></div>
              )}
            </Fragment>
          );
        }
        })}
      </div>
    </div>
  );
};

QuantityHorizontalLegend.propTypes = {
  colorSteps: PropTypes.arrayOf(PropTypes.object).isRequired, // color steps to build the legend
};

export { QuantityHorizontalLegend };
