import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Form,
  Upload,
  Input,
  Col,
  Breadcrumb,
  message,
  Button,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserImageEdit,
  actionUserGet,
} from '../../../actions/index.js';
import '../../style.css';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, UPLOADS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
const { TextArea } = Input;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserImageEdit,
  actionUserGet,
};

const UserForm = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [update, setUpdate] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      {props.user.image && (
        <img
          style={{ width: '40px', height: '40px' }}
          src={`https://assets.akuko.io/${props.user.image}`}
        />
      )}
      <div style={{ marginTop: 8 }}>
        {uploading ? `Uploading... ${progress}%` : 'Upload'}
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
      return false;
    }
    return true;
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const onUploadProgress = (event) => {
        let percent = Math.round((event.loaded * 100) / event.total);
        if (percent === 100) {
          percent = 99;
        }
        setProgress(percent);
        onProgress({ percent });
      };
      const service = new AkukoAPIService(
        UPLOADS_API,
        `images`,
        onUploadProgress
      );
      const response = await service.create(formData);
      const userProfileService = new AkukoAPIService(
        IDENTITY_API,
        `user/profile/image/${props?.user?.uuid}`
      );
      await userProfileService.update({
        email: props?.user?.email,
        imageName: response?.image_id,
      });
      setProgress(100);
      onProgress({ percent: 100 });
      props.actionUserImageEdit({ value: response?.image_id });
      onSuccess(response);
    } catch (error) {
      onError(error);
      message.error('Upload failed.');
    } finally {
      setUploading(false);
    }
  };

  if (props.user.uuid) {
    return (
      <div className='dashboard'>
        <div className='spaces'>
          <div className='breadcrumb'>
            <Breadcrumb>
              <Breadcrumb.Item>{props.user.handle}</Breadcrumb.Item>
              <Breadcrumb.Item>my account</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col sm={16}>
              <h1>My account</h1>
              <Form
                layout='vertical'
                initialValues={{
                  handle: props.user.handle,
                  name: props.user.name,
                  tagline: props.user.tagline,
                }}
                onFinish={(values) => {
                  setUpdate(true);
                  const service = new AkukoAPIService(
                    IDENTITY_API,
                    `user/${props.user.uuid}`
                  );
                  service
                    .update({
                      email: props?.user?.email,
                      handle: values['handle'],
                      tagline: values['tagline'],
                      name: values['name'],
                    })
                    .then((res) => {
                      props.actionUserGet(res);
                      message.success('Account updated');
                    })
                    .catch((err) => {
                      if (err.message) {
                        message.error(err.message);
                      } else {
                        message.error(ERROR_GENERIC);
                      }
                    })
                    .finally(() => {
                      setUpdate(false);
                    });
                }}
              >
                <Form.Item
                  label='handle'
                  name='handle'
                  hasFeedback
                  validateTrigger='onChange'
                  rules={[
                    {
                      required: true,
                      message: 'Handle is not available',
                      validator: (field, value) => {
                        if (value === props.user.handle) {
                          return Promise.resolve();
                        }
                        if (value === '') {
                          return Promise.reject();
                        }
                        const service = new AkukoAPIService(
                          IDENTITY_API,
                          'user/handle/available'
                        );
                        return service
                          .read(value)
                          .then((handle) => {
                            if (handle.available === false) {
                              return Promise.reject();
                            }
                            return Promise.resolve();
                          })
                          .catch((e) => {
                            message.error(e);
                            return Promise.reject();
                          });
                      },
                    },
                  ]}
                >
                  <Input size='large' disabled={update} />
                </Form.Item>
                <Form.Item label='Display Name' name='name'>
                  <Input size='large' disabled={update} />
                </Form.Item>
                <Form.Item label='email'>
                  <Input disabled size='large' value={props.user.email} />
                </Form.Item>
                <Form.Item
                  label='About'
                  name='tagline'
                  rules={[
                    {
                      max: 200,
                    },
                  ]}
                >
                  <TextArea
                    rows='5'
                    placeholder='A few lines about me...'
                    disabled={update}
                  />
                </Form.Item>
                <Button type='primary' htmlType='submit' disabled={update}>
                  {update && <LoadingOutlined />}{' '}
                  {update ? 'Updating profile...' : 'Update Profile'}
                </Button>
                <br />
                <br />
                <Form.Item name='image' label='Profile image'>
                  <ImgCrop>
                    <Upload
                      name='file'
                      listType='picture-card'
                      className='avatar-uploader'
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      customRequest={customRequest}
                    >
                      {uploadButton}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Form>

              <br />

              <h2>Password</h2>
              <Form
                form={form}
                layout='vertical'
                onFinish={(values) => {
                  setShowLoader(true);
                  const service = new AkukoAPIService(
                    IDENTITY_API,
                    'user/change-password'
                  );
                  service
                    .create({
                      userId: props.user?.uuid,
                      email: props.user?.email,
                      newPassword: values['new_password'],
                    })
                    .then(() => {
                      setShowLoader(false);
                      form.resetFields();
                      message.success('Password changed successfully.');
                    })
                    .catch((err) => {
                      setShowLoader(false);
                      if (err.message) {
                        message.error(err.message);
                      } else {
                        message.error(ERROR_GENERIC);
                      }
                    });
                }}
              >
                <Form.Item
                  name='new_password'
                  label='New Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your new password.',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters.',
                    },
                    {
                      pattern: /[A-Z]/,
                      message:
                        'Password must contain at least 1 uppercase letter.',
                    },
                    {
                      pattern: /[a-z]/,
                      message:
                        'Password must contain at least 1 lowercase letter.',
                    },
                    {
                      pattern: /\d/,
                      message: 'Password must contain at least 1 digit.',
                    },
                    {
                      pattern: /[!@#$%^&*(),.?":{}|<>]/,
                      message:
                        'Password must contain at least 1 special character.',
                    },
                    {
                      pattern: /^[^@]+@[^@]+\.[^@]+$/,
                      validator: (_, value) => {
                        if (value && /^[^@]+@[^@]+\.[^@]+$/.test(value)) {
                          return Promise.reject(
                            new Error('Password cannot be an email.')
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password size='large' disabled={showLoader} />
                </Form.Item>
                <Form.Item
                  name='confirm_password'
                  label='Confirm Password'
                  dependencies={['new_password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password.',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Passwords do not match.')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size='large' disabled={showLoader} />
                </Form.Item>
                <Button type='primary' htmlType='submit' disabled={showLoader}>
                  {showLoader && <LoadingOutlined />}{' '}
                  {showLoader ? 'Updating password...' : 'Update Password'}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const UserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);

export default UserFormContainer;
