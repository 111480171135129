import { Dictionary } from '@onaio/utils/dist/types/types';
import { ENV } from '../../../../configs/env';

export const getDomain = (route: string): string => {
  if (ENV === 'prod') {
    return `https://app.akuko.io/${route}`;
  } else {
    return `/${route}`;
  }
};

export const getDashboardLink = (
  user: Dictionary,
  location: Dictionary,
  post: Dictionary,
  params: Dictionary,
  route: string
): string => {
  // has write access via space
  if (post.space_id) {
    let hasSpaceAccess = false;
    user?.spaces?.forEach((item: Dictionary) => {
      if (item.id === post.space_id) {
        hasSpaceAccess = true;
      }
    });
    if (hasSpaceAccess) {
      return `/space/${post.space_id}/${route}`;
    }
  }
  if (location.pathname.includes('/space/')) {
    return `/space/${params.id}/${route}`;
  }
  return `/dashboard/${route}`;
};

export const userHasViewAccess = (
  user: Dictionary,
  location: Dictionary,
  post: Dictionary,
  editorAllowed?: boolean
): boolean => {
  if (location.pathname.includes('post/')) {
    // has write access via space
    if (post.space_id) {
      let hasSpaceAccess = false;
      user?.spaces?.some((item: Dictionary) => {
        if (
          (editorAllowed && item.id === post.space_id && item.role === 'editor') ||
          (item.id === post.space_id && item.role === 'admin') ||
          (item.id === post.space_id && item.role === 'viewer')
        ) {
          hasSpaceAccess = true;
        }
      });
      if (hasSpaceAccess) {
        return true;
      }
      return false;
    }
  }
  return false;
}

export const userHasWriteAccess = (
  user: Dictionary,
  location: Dictionary,
  post: Dictionary,
  editorAllowed?: boolean
): boolean => {
  if (location.pathname.includes('post/')) {
    if (post.author === user.email) {
      return true;
    }
    // has write access via space
    if (post.space_id) {
      let hasSpaceAccess = false;
      user?.spaces?.some((item: Dictionary) => {
        if (
          (editorAllowed && item.id === post.space_id && item.role === 'editor') ||
          (item.id === post.space_id && item.role === 'admin')
        ) {
          hasSpaceAccess = true;
        }
      });
      if (hasSpaceAccess) {
        return true;
      }
      return false;
    }
  }
  return false;
};

// generic write access checker

export const genericWriteAccessHandler = (
  user: Dictionary,
  space_id?: string,
  created_by?: string,
  editorAllowed?: boolean
): boolean => {
  if (!space_id) {
    return false;
  }

  if (created_by === user.email) {
    return true;
  }

  const hasSpaceAccess = user?.spaces?.some(
    (item: Dictionary) =>
      (editorAllowed && item.id === space_id && item.role === 'editor') ||
      (item.id === space_id && item.role === 'admin')
  );

  return hasSpaceAccess ?? false;
};
