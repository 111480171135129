import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import Loader from '../../Post/Loader/Loader'
import { AKUKO_HOST_URL } from '../../../configs/env';

const AuthRedirect = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.signinRedirect({
        redirect_uri: `${AKUKO_HOST_URL}/dashboard`,
    });
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

export default AuthRedirect;
