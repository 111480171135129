import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import './style.css';
import { LayoutContainer } from '../Layout';
import { useFetchComponentData } from '../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CardComponent } from '../../../configs/component-types';
import { useMemo } from 'react';
import { getComponentById } from '../../../reducers/selectors/post';
import { GenericComponent } from '../Component';
import {
  CARD_DEFAULT_NUMBER_PER_ROW,
  CARD_DEFAULT_PAGINATION_SIZE,
  CARD_DEFAULT_QUERY_LIMIT,
} from "../../../configs/constants";
import { usePagination } from "../hooks";
import { ComponentPagination } from "../helpers/ComponentPagination";
import { AppstoreOutlined, LoadingOutlined } from "@ant-design/icons";
import { Dictionary } from "@onaio/utils";
import { getCardSource } from "../Chart/helpers/helpers";
import { sortIgnoreCase } from "../helpers";
import { actionComponentEventAdd } from "../actions";
/* @ts-ignore */
import { card } from "../Component/tests/fixtures";
import { CardScroll } from "./components/scroll";
/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface CardProps {
  componentIndex: number; // index of the card component
  componentId: string; // id of the card component
  dataRow?: Dictionary;
  isEmbed?: boolean;
}

const Card: React.FC<CardProps> = (props: CardProps) => {
  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const { componentIndex, componentId, isEmbed, dataRow } = props;
  const [gridSize, setGridSize] = useState(24 / CARD_DEFAULT_NUMBER_PER_ROW);
  const dispatch = useDispatch();

  const component = useSelector((state) => {
    /* @ts-ignore */
    const card = selectComponentById(state, { componentId });

    if (!card) {
      return undefined;
    }
    return card as CardComponent;
  });

  const [isLoading, data] = useFetchComponentData(
    componentId,
    component?.limit || CARD_DEFAULT_QUERY_LIMIT
  );

  let cardData =
    dataRow && component?.context
      ? data.filter(
          (item) =>
            item[`${component.cube}.${component.context}`] ===
            dataRow[
              `${getCardSource(dataRow, component.context)}.${
                component.context
              }`
            ]
        )
      : data;

  // sort non-numerical card data in a case-insensitive manner

  cardData = sortIgnoreCase(
    cardData,
    component?.sortField,
    component?.cube,
    component?.sortOrder
  );

  const itemsPerRow = Number(component?.perRow);

  useEffect(() => {
    if (itemsPerRow) {
      setGridSize(24 / Number(itemsPerRow));
    } else {
      // Reset to a default in a case where the config was removed
      setGridSize(24 / CARD_DEFAULT_NUMBER_PER_ROW);
    }
  }, [itemsPerRow]);

  const {
    paginatedData,
    onPageChange,
    onPageSizeChange,
    enablePagination,
    simple,
    pageSize,
    pageSizeOptions,
  } = usePagination(componentId, cardData, CARD_DEFAULT_PAGINATION_SIZE);

  if (!component) return null;

  const genericComponentProps = {
    componentIndex,
    componentId,
    dataRow,
    drawerTitle: "Card",
    isEmbed: isEmbed,
  };

  return (
    <GenericComponent {...genericComponentProps}>
      {isLoading && (
        <div className="component-loader">
          <LoadingOutlined />
        </div>
      )}
      {!component.source || !paginatedData.length ? (
        <div className="component-empty">
          {!isLoading && <AppstoreOutlined />}
        </div>
      ) : (
        <>
          {component.format === "scroll" ? (
            <CardScroll
              componentId={componentId}
              componentIndex={componentIndex}
            />
          ) : (
            <Row gutter={component?.cardGutter || 20}>
              {paginatedData.map((item, cardIndex) => {
                const layoutContainerProps = {
                  componentIndex,
                  parentIndex: componentIndex,
                  dataRow: item,
                  cardIndex: cardIndex,
                  componentId: componentId,
                  isEmbed: isEmbed,
                };

                return (
                  <Col key={item.id} xs={24} sm={gridSize}>
                    <div
                      id={item.id}
                      onClick={() => {
                        if (component.events?.length > 0) {
                          // add card click event
                          const elems = document.querySelectorAll(".card");
                          [].forEach.call(elems, (el: Element) => {
                            el.classList.remove("card-highlight");
                          });
                          const element = document.getElementById(item.id);
                          dispatch(
                            actionComponentEventAdd({
                              type: "onCardClick",
                              event: { ...item, cube: `${component.cube}` },
                              componentId: component.id,
                            })
                          );
                          element?.classList.add("card-highlight");
                        }
                      }}
                      className={`card ${
                        component?.events?.length > 0 ? "card-clickable" : ""
                      }`}
                      style={{
                        background: component?.backgroundColor || "transparent",
                        minHeight: component?.cardHeight || "auto",
                        padding: `${component?.padding}px` || "0px",
                        marginBottom: `${component?.rowMargin}px` || "10px",
                        marginTop: `${component?.rowMargin}px` || "10px",
                        boxShadow: component?.cardShadow
                          ? `0 1px 7px rgb(0 0 0 / 20%)`
                          : "none",
                        border: component?.cardBorder
                          ? `1px solid rgba(0,0,0,0.1)`
                          : "none",
                      }}
                    >
                      <LayoutContainer key={0} {...layoutContainerProps} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}

          {enablePagination && (
            <Row>
              <ComponentPagination
                total={cardData.length}
                pageSize={pageSize}
                simple={simple}
                pageSizeOptions={pageSizeOptions}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
            </Row>
          )}
        </>
      )}
    </GenericComponent>
  );
};

export { Card };
