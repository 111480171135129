import { CategoriesLegend } from './CategoriesLegend';
import { HeatmapLegend } from './HeatmapLegend';
import {
  LegendOptions,
  QuantityHorizontalLegend,
} from './QuantityHorizontalLegend';
import { SymbolLegend } from './SymbolLegend';
import React from 'react';
import { LayerProps, LegendValueProps } from '../../../../../configs/types';

/**
 * Get legend for a map layer
 *
 * @param {object} layer map layer to get legend for
 * @returns {JSX.Element} map legend
 */
export const getLegend = (layer: LayerProps): JSX.Element | null => {
  if (layer.symbolCategories && layer.layerType === 'symbol') {
    const sorted = [...layer.symbolCategories]
      .filter((category) => category.symbol && category.symbol.id)
      .sort((a, b) => {
        if (a.value < b.value) return -1;

        if (a.value > b.value) return 1;

        return 0;
      });
    const legendProps = {
      categories: sorted,
    };
    return <SymbolLegend {...legendProps} />;
  }
  if (layer.colorMethod === 'interpolate') {
    const legendProps = {
      low: layer.fillFieldMinValue,
      high: layer.fillFieldMaxValue,
      startColor: layer.fillFieldStartColor,
      endColor: layer.fillFieldEndColor,
      legendOptions: layer.legend as LegendOptions,
    };
    return <HeatmapLegend {...legendProps} />;
  }

  if (layer.colorMode === 'steps' && layer.colorSteps?.length) {
    const legendProps = {
      colorSteps: layer.colorSteps as LegendValueProps[],
      legendOptions: layer.legend,
      classes: layer?.classes
    };
    return <QuantityHorizontalLegend {...legendProps} />;
  }

  if (layer.colorMethod === 'categorical') {
    const legendProps = {
      colorCategories: layer.colorCategories,
      legendOptions: layer.legend as LegendOptions,
    };
    return <CategoriesLegend {...legendProps} />;
    //}
  } else if (layer.colorMethod === 'breaks') {
    if (layer.colorBreaks && layer.colorBreaks?.length > 0) {
      const legendProps = {
        colorSteps: layer.colorBreaks as LegendValueProps[],
        legendOptions: layer.legend,
        colorMethod: layer.colorMethod,
        classes: layer?.classes
      };
      return <QuantityHorizontalLegend {...legendProps} />;
    }
  }

  return null;
};

/**
 * Sort legend color steps in ascending order
 *
 * @param {object} colorSteps color steps to be sorted
 * @returns {Array} sorted array
 */
export const sortColorSteps = (
  colorSteps: LegendValueProps[]
): LegendValueProps[] => {
  if (!colorSteps) {
    colorSteps = [];
  }
  const parsedSteps = colorSteps.map((step: LegendValueProps) => {
    return {
      ...step,
      value:
        typeof step.value === 'string' ? parseFloat(step.value) : step.value,
    };
  });
  return parsedSteps.sort((a, b) => a.value - b.value);
};
