import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'antd';
import { getComponentById } from '../../../../reducers/selectors/post';
import { NumberComponent } from '../../../../configs/component-types';
import { useFetchComponentData } from '../../hooks';
import { Dictionary } from '@onaio/utils';
import { getCardSource } from '../../Chart/helpers/helpers';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { IconComponentIcon } from '../../../../configs/component-types';

export interface IconPropertiesProps {
  componentIndex: number; // index of component
  componentId: string; // id of component
  dataRow?: Dictionary;
}

const makeGetComponentByIndex = () => getComponentById;

const Icon: React.FC<IconPropertiesProps> = (props: IconPropertiesProps) => {
  const { componentId, dataRow } = props;

  // memoize selectors
  const selectComponentByIndex = useMemo(makeGetComponentByIndex, []);

  const component = useSelector((state) => {
    /* @ts-ignore */
    const number = selectComponentByIndex(state, { componentId });
    if (!number) {
      return undefined;
    }
    return number as NumberComponent;
  });

  const [, data] = useFetchComponentData(componentId);
  let dataFiltered = data;

  let dataRowComparable =
    dataRow?.[`${getCardSource(dataRow, component?.context)}.${component?.context}`];

  // check if dataRow comes from map popup

  if (component?.context && dataRow?.[component?.context]) {
    dataRowComparable = dataRow?.[component.context];
  }

  if (dataRow && component?.properties && component?.properties[0]) {
    dataFiltered = data.filter(
      (item) => item[`${component?.cube}.${component?.context}`] === dataRowComparable
    );
  }

  const getColor = () => {
    if (component?.colors) {
      const color = component?.colors.filter(
        (item) => item.property === dataFiltered[0]?.[`${component.cube}.${component?.colorField}`]
      );
      if (color[0] && color[0].value) {
        return hexToCSSFilter(color[0].value).filter.replace(';', '');
      }
    }
    if (component?.color) {
      return hexToCSSFilter(component?.color).filter.replace(';', '');
    }
    return '#111111';
  };

  const getIcon = () => {
    if (component?.iconCategories) {
      const icon = component?.iconCategories.filter(
        (item) => item.property === dataFiltered[0]?.[`${component.cube}.${component?.iconField}`]
      );
      if (icon[0] && icon[0].value) {
        return icon[0].value as IconComponentIcon;
      }
    }
    if (component?.icon) {
      return component.icon as IconComponentIcon;
    }
    return {
      src: 'https://s3.amazonaws.com/assets.akuko.io/ocha-icons-black-svg/Add.svg',
      title: 'Add',
    };
  };

  const icon = getIcon();

  const imageProps = {
    preview: false,
    width: '100%',
    height: component?.height || 40,
    src: icon.src,
    alt: icon.title,
    style: {
      filter: getColor(),
    },
  };

  return (
    <div
      className="icon-component"
      style={{
        background: component?.backgroundColor || 'rgba(0,0,0,0.02)',
      }}
    >
      <div
        className="icon-wrapper"
        style={{
          width: `${component?.height || 50}px`,
          height: `${component?.height || 50}px`,
        }}
      >
        <Image {...imageProps} />
      </div>
    </div>
  );
};

export { Icon };
