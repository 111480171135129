import { Dictionary } from "@onaio/utils";
import { MouseEvent, ChangeEvent } from "react";
import {
  ColorValueProps,
  FieldProps,
  SymbolValueProps,
} from "../configs/types";

declare global {
  interface Window {
    akukoQueryIndex: string[];
  }
}

export type PostComponentType =
  | 'title'
  | 'text'
  | 'chart'
  | 'map'
  | 'image'
  | 'table'
  | 'card'
  | 'layout'
  | 'menu'
  | 'number'
  | 'button'
  | 'reference'
  | 'icon'
  | 'html'
  | 'filter';

export type SourcePropertyType = "string" | "number" | "time";

export type MapLayerType = "symbol" | "circle" | "line" | "fill";

export type DataItemTypes = "color" | "categories" | "iconCategories";

export type ButtonOnClickEvent = MouseEvent<HTMLInputElement>;

export type InputChangeEvent = ChangeEvent<HTMLInputElement>;

export interface FilterOption {
  exposed?: boolean;
  separateTextByCommas?: boolean;
  actualDateFormat?: boolean;
  showTextField?: boolean;
  slider?: boolean;
  datePicker?: boolean;
  cols?: boolean;
  name?: string;
  multiple?: boolean;
  inherit?: boolean;
  single?: boolean;
  disableClear?: boolean;
  type: string;
  0: string;
  1: string;
  2: string;
  3?: string;
  4?: string;
  id: string;
  dataType?: string;
  maxValue?: number;
  granularity?: string;
}
export interface FilterValues {
  0: string;
  1: string;
  2: string[] | [] | string;
  3?: string[] | [] | string;
  4?: string[] | [] | string;
}

export interface IconComponentIcon {
  id: string;
  src: string;
  title: string;
  color: string;
  alt?: string;
}

export interface ColorSteps {
  id: string;
  value: string;
  color: string;
}

export interface DataField {
  id: string;
  property: string;
  value: IconComponentIcon | string;
}

export interface IconCategory {
  id: string;
  property: string;
  value: IconComponentIcon;
}

export interface ColorCategory {
  id: string;
  property: string;
  value: string;
}

export interface Dimension {
  type: SourcePropertyType;
  value: string;
  title?: string;
  prefix?: string;
  suffix?: string;
  format?: string;
  sql?: string;
  custom?: boolean;
}

export interface Measure {
  sql: string;
  name: string;
  title?: string;
  prefix?: string;
  suffix?: string;
  format?: string;
  filters?: Dictionary[];
  type: SourcePropertyType;
}

export interface Source {
  name: string;
  type: string;
  uuid: string;
  dimensions: Dimension[];
  geometries?: Dictionary[];
  measures: Measure[] | null;
  cube: string;
  labels: Dictionary;
  refresh_key?: number | string;
  url: string;
  public: boolean;
  schema: Dictionary;
}

export interface ComponentProperty {
  id: string;
  type: string;
  value: string;
  visible?: boolean;
  propertyAlias?: string;
  maxValue?: number;
  fixedColumn?: boolean;
  columnPosition?: string;
  columnWidth?: number;
  format?: string;
  icon?: IconComponentIcon;
  iconSize?: number;
  iconColor?: string;
  iconField?: string;
  colorMode?: string;
  iconCategories?: IconCategory[];
  "data-type"?: string;
  colorSteps?: ColorSteps[];
  contextFromRow?: boolean;
  dateFormat?: string;
  colors?: ColorCategory[];
  colorField?: string;
  generatedSteps: ColorSteps[];
  colorCategories: ColorSteps[];
}

export interface PostComponentFilterBy {
  type: string;
  value: string;
}

export interface GenericPostComponent {
  id: string;
  layoutId?: string;
  name?: string;
  cube: string;
  region: number;
  layout?: string;
  borderColor?: string;
  type: PostComponentType;
  title: string;
  caption: string;
  fontFamily: string;
  source: string;
  field?: DataItemTypes;
  categories?: DataField[];
  iconCategories?: DataField[];
  colors?: DataField[];
  color?: string;
  fontSize?: number;
  steps?: DataField[];
  filters?: FilterOption[];
  filterOptions?: FilterOption[];
  properties: ComponentProperty[];
  height?: number;
  padding?: number[];
  public?: boolean;
  isEmbed?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any[];
  width?: string;
  icon?: IconComponentIcon;
  size?: "large" | "middle" | "small";
  position: string;
  events?: Dictionary[];
  format?: string;
  filterBy?: PostComponentFilterBy;
  items?: Dictionary[];
  pagination?: boolean;
  pageSizeOptions?: string[];
  defaultPageSize?: number | string;
  showSizeChanger?: boolean;
  simplePager?: boolean;
  sortField?: string;
  sortOrder?: string;
  globalFilters?: string[][];
  componentsWithGlobalFilters?: number[] | [];
  granularity?: string;
  cascade?: boolean;
  backgroundColor?: string;
  dataExport?: boolean;
  spaceComponent?: boolean;
  componentImageExport?: boolean;
  marginTop?: number | string;
  marginRight?: number | string;
  marginLeft?: number | string;
  marginBottom?: number | string;
  activeTab?: string;
  collapse?: Dictionary[];
  tabs?: Dictionary[];
  expandIconPosition?: "start" | "end";
  accordion?: boolean;
  placement?: "right" | "left" | "top" | "bottom";
  bordered?: boolean;
  ghost?: boolean;
  hidden?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center";
  offset?: number;
  limit?: number;
}

export enum ChartType {
  AREA = "area",
  BAR = "bar",
  COLUMN = "column",
  LINE = "line",
  PIE = "pie",
  POLAR = "polar",
  RADAR = "radar",
  SCATTER = "scatterPlot",
  STACKED_BAR = "barStack",
  STACKED_AREA = "areaStack",
  STACKED_COLUMN = "columnStack",
  BUBBLE = "bubble",
  TREEMAP = "treeMap",
}

export interface ChartComponentXAxis {
  type: string;
  value: string;
  dataType: string;
}

export interface ChartGroupByColors {
  color: string;
  value: string;
}

export interface ChartSeries {
  color: string;
  value: string;
  value_type: string;
  groupBy: string;
  colorMode: string;
  groupByColors: ChartGroupByColors[];
  ungroupedData: ChartGroupByColors[];
}

export type CardChild = TextComponent | ChartComponent;

export interface CardComponent extends GenericPostComponent {
  type: "card";
  perRow?: number | string;
  context?: string;
  rowMargin?: number;
  cardBackground?: boolean;
  backgroundColor?: string;
  cardShadow?: boolean;
  cardBorder?: boolean;
  cardGutter?: number;
  cardPadding?: number;
  cardHeight?: number;
  limit?: number | undefined;
  sortField?: string;
  sortOrder?: string;
  events: Dictionary[];
  highlights: Dictionary[];
}

export interface ChartComponent extends GenericPostComponent {
  type: "chart";
  hideLabels?: boolean;
  legend?: boolean;
  sortField?: string;
  sortOrder?: string;
  limit?: number | undefined;
  backgroundColor: string;
  chartType?: ChartType;
  xAxis?: ChartComponentXAxis;
  series?: ChartSeries[];
  yAxisMin?: number;
  yAxisMax?: number;
  context?: string;
  dateFormat?: string;
  showPercentageChart?: boolean;
  xLabelOffset?: number;
  yLabelOffset?: number;
  xLabelRotate?: number;
  yLabelRotate?: number;
  seriesTickCount?: number;
  categoryTickCount?: number;
  xAxisMin?: number;
  xAxisMax?: number;
  labelFill?: number;
  ytextMaxLength?: number;
  xtextMaxLength?: number;
  hideCategoryLabel?: boolean;
  hideSeriesLabel?: boolean;
  strokeColor?: string;
  opacity?: number;
  strokeOpacity?: number;
  lineWidth?: number;
  bubbleProperties?: Dictionary;
  customToolTipProperties?: Dictionary;
  disableFillNullValues?: Dictionary;
  gridLines?: boolean;
  seriesTickInterval?: boolean;
  categoryTickInterval?: boolean;
}

export interface TextComponent
  extends Pick<GenericPostComponent, "id" | "type"> {
  type: "text";
  text: string;
  layout: string;
  context: string;
  cube: string;
  source: string;
  lineHeight: number;
  textAlign?: string;
  color: string;
  fontSize: string;
  fontFamily: string;
}

export interface ReferenceComponent
  extends Pick<GenericPostComponent, "id" | "type"> {
  type: "reference";
  componentId: string;
}

export interface NumberComponent extends GenericPostComponent {
  type: "text";
  context?: string;
  layout: string;
  format: string;
  color: string;
  height: number;
  showValue: boolean;
  colorField: string;
  numberHeight?: number;
  iconField: string;
  backgroundColor: string;
  icon: IconComponentIcon;
}

export interface ImageComponent extends GenericPostComponent {
  type: "image";
  layout: string;
  height: number;
}

export interface ColumnProperties {
  visible: boolean;
}

export interface TableComponent extends Partial<GenericPostComponent> {
  id: string;
  type: "table";
  layout: string;
  pagination?: boolean;
  pivotRows: string;
  pivotColumns: string;
  pivotValues: string;
  showHeader: boolean;
  bordered: boolean;
  fixedHeader?: boolean;
  fontSize: number;
  fontFamily: string;
  ellipsis?: boolean;
  context?: string;
  colorText?: string;
  cellPaddingBlock: number;
  borderColor: string;
  headerBg: string;
  headerSortActiveBg: string;
  headerSortHoverBg: string;
  headerSplitColor: string;
  headerColor: string;
  colorBgContainer: string;
}

export interface IconComponentIcon {
  id: string;
  src: string;
  title: string;
  color: string;
  sort?: string;
}

export interface IconComponentCategory {
  value: string;
  icon: IconComponentIcon;
}

export interface IconComponent extends Partial<GenericPostComponent> {
  type: "icon";
  source?: string;
  useCategories: boolean;
  context?: string;
  icon?: IconComponentIcon;
  backgroundColor: string;
  colorField: string;
  iconField: string;
}

export interface ComponentsBasedOnFilterSource {
  componentIndex: number;
  type: string;
  id: string;
}
export interface GlobalFilterComponent extends Partial<GenericPostComponent> {
  type: "filter";
}
export interface MapLayer {
  id: string;
  layerType: MapLayerType;
  layerName: string;
  source: string;
  visible: boolean;
  geometry: number;
  children?: this[];
  filters?: FilterOption[];
  layerBefore?: string;
  globalFilters?: string[][];
  property?: string;
  group?: string;
  section?: string;
  item?: string;
  itemLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  fields?: FieldProps[];
  tooltip?: string;
  joinField?: string;
  targetField?: string;
  geometryIndex?: number;
  geometries?: Dictionary[];
  circleRadius?: number;
  minMaxZoom?: number[];
  hasMenuItem?: boolean;
  minZoom?: number;
  maxZoom?: number;
  hasToggle?: boolean;
  layerGroupName?: string;
  legend?: Dictionary;
  colorMethod?: string;
  colorMode?: string;
  colorSteps?: ColorValueProps[];
  colorCategories?: ColorValueProps[];
  colorBreaks?: ColorValueProps[];
  hasPopup?: boolean;
  contextFromPopup?: boolean;
}

export interface SymbolLayerIcon {
  id: string;
  src: string;
  color: string;
  title: string;
}

export interface SymbolLayerCategory {
  value: string;
  symbol: SymbolLayerIcon;
}

export interface SymbolLayer extends MapLayer {
  layerType: "symbol";
  symbolField?: string;
  icon?: SymbolLayerIcon;
  symbolPrefix?: string;
  symbolCategories?: SymbolValueProps[];
  symbolSuffix?: string;
  symbolSize?: string;
  symbolOffsetX?: string;
  symbolOffsetY?: string;
}

export interface FillLayer extends MapLayer {
  layerType: "fill";
  symbolField?: string;
  icon?: SymbolLayerIcon;
  fillFieldMinValue?: number;
  fillFieldMaxValue?: number;
  fillFieldStartColor?: string;
  fillFieldEndColor?: string;
}

export interface MapComponent extends GenericPostComponent {
  type: "map";
  key: string;
  zoom: number;
  pitch: number;
  defaultPitch: string | number;
  defaultBearing: string | number;
  bearing: number;
  latitude: number;
  longitude: number;
  mapStyle: string;
  visible: boolean;
  layers: MapLayer[];
  styleOption: string;
  styleColor: string;
  darkMode: string;
  defaultZoom: number;
  defaultLongitude: number;
  defaultLatitude: number;
  defaultPosition: Dictionary;
  tour: Dictionary[];
  zoomControl: boolean | undefined;
  locateControlHorizontalPosition: "right" | "left" | undefined;
  locateControlVerticalPosition: "top" | "bottom" | undefined;
  zoomControlHorizontalPosition: "right" | "left" | undefined;
  zoomControlVerticalPosition: "top" | "bottom" | undefined;
  enableScrollZoom: boolean;
  projection?: string;
  terrain?: string;
  locateControl?: boolean;
  scaleBar?: boolean | undefined;
  fogColor?: string;
  highColor?: string;
  spaceColor?: string;
}

export interface SelectOption {
  name: string;
  value: string;
}

export interface UserAccount {
  id: string;
  role: string;
}

export interface SourceQueryObject {
  uuid: string;
  refreshKey?: number | string;
  cubeName: string;
  query: {
    dimensions: string[];
    measures: string[];
    filters: Dictionary[];
    limit: number;
    order?: Dictionary;
    timeDimensions?: string | string[];
    granularity?: string;
    offset?: number;
  };
}

export interface QueryExportParams {
  cube_query: {
    query: {
      query: {
        dimensions?: string[];
        measures?: string[];
        filters?: Dictionary[];
        limit?: number;
        order?: Dictionary;
        timeDimensions?: string | string[];
        granularity?: string;
      };
      uuid?: string;
    };
  };
  refresh_key: number;
  cube_name: string;
}

export interface csvSourceParams {
  sourceId: string;
  sourceType: string;
  formData?: FormData;
  url?: string;
  refresh: boolean;
  name: string;
  onUploadProgress?: (e: Dictionary) => void | undefined;
  tippecanoe_options?: string[];
}

export interface parquetSourceFromFileParams {
  sourceId: string;
  formData: FormData;
  cube_name: string;
  onUploadProgress?: (e: Dictionary) => void | undefined;
}

export interface geoParquetSourceFromUrlParams {
  sourceId: string;
  cubeName: string;
  geoParquetUrl: FormData;
  tippecanoeOptions: string[];
}

export interface geoParquetSourceFromFileParams {
  sourceId: string;
  formData: FormData;
  cubeName: string;
  tippecanoe_options: string[];
  onUploadProgress?: (e: Dictionary) => void | undefined;
}

export interface sourceGeometryFromPMTilesFileParams {
  name: string;
  sourceId: string;
  formData: FormData;
  onUploadProgress?: (e: Dictionary) => void | undefined;
}

export interface sourceGeometryFromPMTilesServerParams {
  name: string;
  sourceId: string;
  mvtTilesUrl: string;
  layerName: string;
  maxZoom: string;
  minZoom: string;
}

export interface googleSheetSourceParams {
  sourceId: string;
  url: string;
  sheet: string;
  type: string;
  name: string;
  cube: string;
}

export interface postgresSQLSourceParams {
  sourceId: string;
  type: string;
  name: string;
  cube: string;
  schema: string;
  table: string;
  user: string;
  host: string;
  database: string;
  password: string;
  port: number;
}

export interface AWS_S3_CREDENTIALS {
  aws_s3_access_key_id: string;
  aws_s3_secret_key_id: string;
}

export interface AWS_S3_DETAILS {
  aws_s3_credentials?: AWS_S3_CREDENTIALS;
  aws_s3_region: string;
  aws_s3_bucket_name: string;
  aws_s3_file_path: string;
}

export interface S3ParquetSourceParams {
  sourceId: string;
  cube_name: string;
  aws_s3_details: AWS_S3_DETAILS;
}

export interface updateS3UserSecretsForS3ParquetSourceParams {
  sourceId: string;
  awsS3Credentials: AWS_S3_CREDENTIALS;
}

export interface MySQLSourceTypePayload {
  source_name: string;
  refresh: boolean;
  table: string;
  user: string;
  host: string;
  database: string;
  password: string;
  port: number;
  cube_name: string;
  source_type: string;
  source_id: string;
}
export interface MySQLSourceTypeRequestPayload {
  sourceType: string;
  sourceId: string;
  formData: FormData;
}
export interface ClickHouseSourceTypeRequestPayload {
  sourceId: string;
  formData: FormData;
}

export interface ClickHouseSourceTypePayload {
  host: string;
  protocol: string;
  table: string;
  username: string;
  database: string;
  password: string;
  port: number;
}

export interface SourceRefreshScheduleRequestPayload {
  sourceId: string;
  payload: Dictionary;
}
export interface PostScheduleSendRequestPayload {
  postId: string;
  payload: Dictionary;
}
export interface geometryCreateRequestPayload {
  sourceId: string;
  endpointName: string;
  payload: Dictionary;
}
export interface geometryUpdateRequestPayload {
  geometryId: string;
  endpointName: string;
  payload: Dictionary;
}

export interface SourceRefreshRequestPayload {
  sourceId: string;
}

export interface QueryParams {
  query: {
    query: {
      dimensions: string[];
      filters: Dictionary[];
      limit: number;
      measures: string[];
      order: Dictionary;
      timeDimensions: string[];
    };
    uuid: string;
  };
}

export interface PostExportParams {
  post_id: string;
  post_url: string;
  format: string;
  selector: string;
  post_filters?: string[];
  emails?: string[];
  download_file_name: string;
}

export enum sourceAsyncOperationsJobStatusTypes {
  STARTED = "STARTED",
  PENDING = "PENDING",
  PROGRESS = "PROGRESS",
  DONE = "DONE",
  FAILED = "FAILED",
}

export enum sourceRefreshStatusTypes {
  STARTED = "STARTED",
  REFRESHING_DATA = "REFRESHING_DATA",
  REGENERATING_GEOMETRIES = "REGENERATING_GEOMETRIES",
  UPDATING_METADATA = "UPDATING_METADATA",
  CLEARING_POSTS_CACHE = "CLEARING_POSTS_CACHE",
  DONE = "DONE",
}

export enum postScheduleSendJobStatusTypes {
  TESTING_POST_EXPORT = "TESTING_POST_EXPORT",
  UPDATING_POST_REFRESH_METADATA = "UPDATING_POST_REFRESH_METADATA",
  CREATE_POST_EXPORT_SCHEDULE = "CREATE_POST_EXPORT_SCHEDULE",
  DONE = "DONE",
}

export enum postExportJobStatusTypes {
  TRIGGERING_ASYNC_POST_EXPORT = "TRIGGERING_ASYNC_POST_EXPORT",
  WAITING_FOR_POST_EXPORT_TO_BE_READY = "WAITING_FOR_POST_EXPORT_TO_BE_READY",
  DONE = "DONE",
}

export enum geojsonSourceCreationJobStatusTypes {
  UPLOADING = "UPLOADING",
  STARTED = "STARTED",
  UPDATING_SOURCE_METADATA = "UPDATING_SOURCE_METADATA",
  UPDATING_GEOJSON_FEATURE_PROPERTY_NAMES = "UPDATING_GEOJSON_FEATURE_PROPERTY_NAMES",
  CREATING_PMTILES_FROM_GEOJSON = "CREATING_PMTILES_FROM_GEOJSON",
  GENERATING_CUBE_DIMENSIONS_FROM_CSV = "GENERATING_CUBE_DIMENSIONS_FROM_CSV",
  RUNNING_CLICKHOUSE_UPDATES = "RUNNING_CLICKHOUSE_UPDATES",
  UPDATING_SOURCE = "UPDATING_SOURCE",
  DONE = "DONE",
}

export enum GeometryUpdateJobStatusTypes {
  STARTING = "STARTING",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export const GeometryUpdateJobStatusTypesDescriptions = {
  STARTING: "Starting",
  PROCESSING: "Processing",
  DONE: "Done",
};

export enum FileSourceCreationJobStatusTypes {
  UPLOADING = "UPLOADING",
  STARTING = "STARTING",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export const FileSourceCreationJobStatusTypesDescriptions = {
  UPLOADING: "Uploading",
  STARTING: "Starting job",
  PROCESSING: "Processing",
  DONE: "Done",
};

export enum UrlSourceCreationJobStatusTypes {
  STARTING = "STARTING",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export const UrlSourceCreationJobStatusTypesDescriptions = {
  STARTING: "Starting job",
  PROCESSING: "Processing",
  DONE: "Done",
};

export const geojsonSourceCreationRefreshJobStatusTypesDescriptions = {
  UPLOADING: "Uploading file",
  STARTED: "Triggering source creation job",
  UPDATING_SOURCE_METADATA: "Updating source metadata",
  UPDATING_GEOJSON_FEATURE_PROPERTY_NAMES:
    "Updating geojson feature property names",
  CREATING_PMTILES_FROM_GEOJSON: "Creating pmtiles from geojson",
  GENERATING_CUBE_DIMENSIONS_FROM_CSV:
    "Generating cube schema from geojson csv",
  RUNNING_CLICKHOUSE_UPDATES: "Applying database updates",
  UPDATING_SOURCE: "Updating source",
  DONE: "Done",
};

export enum csvSourceCreationJobStatusTypes {
  UPLOADING = "UPLOADING",
  STARTING = "STARTING",
}

export const csvSourceCreationRefreshJobStatusTypesDescriptions = {
  UPLOADING: "Uploading",
  STARTING: "Starting job",
};

export enum geometryGenerationProgressStatusTypes {
  STARTED = "STARTED",
  DONE = "DONE",
}

export enum geometryGenerationEndpointTypes {
  LATLONG = "lat-long",
  JOIN = "join",
  COLUMN = "column",
  SOURCE = "tippecanoe",
  PMTILES_FILE = "pmtiles",
}

export enum geometryTypes {
  LATLONG = "latLng",
  JOIN = "join",
  COLUMN = "geometry",
  SOURCE = "source",
  PMTILES_FILE = "PMTiles",
  PMTILES = "PMTiles",
}

export enum PMTilesInputTypes {
  FILE = "file",
  URL = "url",
}

export const sourceRefreshJobStatusTypesDescriptions = {
  STARTED: "Triggering source refresh job",
  REFRESHING_DATA: "Refreshing source data",
  REGENERATING_GEOMETRIES: "Regenerating source geometries",
  UPDATING_METADATA: "Updating source metadata",
  CLEARING_POSTS_CACHE: "Clearing cache for posts linked to source",
  DONE: "Done",
};

export enum defaultProgressStatusTypesForAsyncTasks {
  STARTED = 'STARTED',
  DONE = 'DONE',
}

export interface updateSourceCubeSchemaRequestPayload {
  sourceId: string;
  schema: Dictionary;
  cubeName: string;
}

type TCubeMemberType = 'time' | 'number' | 'string' | 'boolean';

export type BaseCubeMember = {
  type: TCubeMemberType;
  name: string;
  title: string;
  shortTitle: string;
  description?: string;
  public?: boolean;
  meta?: Dictionary;
};

export type TCubeMeasure = BaseCubeMember & {
  aggType: 'count' | 'number';
  cumulative: boolean;
  cumulativeTotal: boolean;
  drillMembers: string[];
  drillMembersGrouped: {
    measures: string[];
    dimensions: string[];
  };
  format?: 'currency' | 'percent';
};

export type TCubeDimension = BaseCubeMember & {
  primaryKey?: boolean;
  suggestFilterValues: boolean;
};

export type TCubeSegment = Omit<BaseCubeMember, 'type'>;

export interface updateSourceDimensionsRequestPayload {
  sourceId: string;
  dimensions: TCubeDimension[];
  cubeName: string;
};

export interface updateSourceMeasuresRequestPayload {
  sourceId: string;
  measures: TCubeMeasure[];
  cubeName: string;
};

export interface deleteSourceCubeDimensionsRequestPayload {
  sourceId: string;
  dimensionNames: string[];
  cubeName: string;
};

export interface deleteSourceCubeMeasuresRequestPayload {
  sourceId: string;
  measureNames: string[];
  cubeName: string;
};
