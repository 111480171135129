import { Dictionary } from '@onaio/utils/dist/types/types';
import { getBounds, getLatLng } from '../Map/components/MapInstance/helpers';
import axios from 'axios';
import { message } from 'antd';
import {
  actionPostComponentSettingEdit,
  actionComponentFilterEdit,
} from '../actions';
import { actionComponentMapLayerVisibleEdit } from '../Map/actions';
import { replaceEventTokens } from './helpers';
import mapboxgl from 'mapbox-gl';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    akukoMaps: any;
  }
}

window.akukoMaps = window.akukoMaps || {};

export const handleComponentEvent = (
  events: Dictionary[],
  post: Dictionary,
  dispatch: () => void
): void => {
  const event = events?.[0];
  if (event) {
    post.components.forEach((component: Dictionary) => {
      if (component?.events?.length > 0) {
        component.events.forEach((e: Dictionary) => {
          if (event.event === e.type) {
            if (event.componentId === component.id) {
              fireEventAction(event, post, e, dispatch);
            }
            if (event.event === 'onFilterChange') {
              fireEventAction(event, post, e, dispatch, component.id);
            }
          }
        });
      }
      if (component?.items) {
        component.items.forEach((btn: Dictionary) => {
          if (btn?.events) {
            btn.events.forEach((e: Dictionary) => {
              if (event.event === e.type) {
                if (event?.componentId === btn.id) {
                  fireEventAction(event, post, e, dispatch, component.id);
                }
                if (event.event === 'onFilterChange') {
                  fireEventAction(event, post, e, dispatch, component.id);
                }
              }
            });
          }
        });
      }
      if (component?.layers) {
        component.layers.forEach((layer: Dictionary) => {
          if (layer?.events) {
            layer.events.forEach((e: Dictionary) => {
              if (event.event === e.type) {
                if (event?.layer?.id === layer.id) {
                  fireEventAction(
                    event,
                    post,
                    e,
                    dispatch,
                    component.id,
                    layer
                  );
                }
                if (event.event === 'onFilterChange') {
                  fireEventAction(
                    event,
                    post,
                    e,
                    dispatch,
                    component.id,
                    layer
                  );
                }
              }
            });
          }
        });
      }
    });
  }
};

const fireEventAction = async (
  event: Dictionary,
  post: Dictionary,
  config: Dictionary,
  dispatch: (data: Dictionary) => void,
  componentId?: string,
  layer?: Dictionary
) => {
  switch (config.effect) {
    case 'toggleLayer':
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'map') {
          c.layers.forEach((l: Dictionary, lIndex: number) => {
            if (config.target.includes(l.id)) {
              setTimeout(() => {
                if (event?.trigger?.target?.checked === true) {
                  dispatch(
                    actionComponentMapLayerVisibleEdit({
                      componentIndex: cIndex,
                      layerIndex: lIndex,
                      property: 'visible',
                      value: true,
                    })
                  );
                }
                if (event?.trigger?.target?.checked === false) {
                  dispatch(
                    actionComponentMapLayerVisibleEdit({
                      componentIndex: cIndex,
                      layerIndex: lIndex,
                      property: 'visible',
                      value: false,
                    })
                  );
                }
                if (event?.trigger?.target?.checked === undefined) {
                  dispatch(
                    actionComponentMapLayerVisibleEdit({
                      componentIndex: cIndex,
                      layerIndex: lIndex,
                      property: 'visible',
                      value: l.visible ? false : true,
                    })
                  );
                }
              }, 100);
            }
          });
        }
      });
      break;
    case 'updateFilterValue':
      {
        if (event?.trigger?.target?.checked === false) {
          return null;
        }
        let eventValue = '';
        if (event.feature?.properties[config.filter]) {
          eventValue = event.feature?.properties[config.filter];
        }
        if (event.trigger) {
          Object.keys(event.trigger).forEach((key) => {
            const name = key.split('.')[1];
            if (name === config.filter) {
              eventValue = event.trigger[key];
            }
          });
        }
        if (eventValue === '') {
          eventValue = config.value;
        }
        post.components.forEach((c: Dictionary, cIndex: number) => {
          c?.filters?.forEach((filter: Dictionary, filterIndex: number) => {
            if (filter[1] === config.filter && filter.inherit === true) {
              dispatch(
                actionComponentFilterEdit({
                  componentIndex: cIndex,
                  filterIndex: filterIndex,
                  propertyIndex: 2,
                  value: eventValue,
                  event: true,
                })
              );
            }
          });
          if (c.layers) {
            c.layers.forEach((layer: Dictionary, layerIndex: number) => {
              layer?.filters?.forEach(
                (filter: Dictionary, filterIndex: number) => {
                  if (filter[1] === config.filter && filter.inherit === true) {
                    dispatch(
                      actionComponentFilterEdit({
                        componentIndex: cIndex,
                        layerIndex: layerIndex,
                        filterIndex: filterIndex,
                        propertyIndex: 2,
                        value: eventValue,
                        event: true,
                      })
                    );
                  }
                }
              );
            });
          }
        });
      }
      break;
    case 'clearFilterValue':
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        c?.filters?.forEach((filter: Dictionary, filterIndex: number) => {
          if (filter[1] === config.filter && filter.inherit === true) {
            dispatch(
              actionComponentFilterEdit({
                componentIndex: cIndex,
                filterIndex: filterIndex,
                propertyIndex: 2,
                value: undefined,
                event: true,
              })
            );
          }
        });
        if (c.layers) {
          c.layers.forEach((layer: Dictionary, layerIndex: number) => {
            layer?.filters?.forEach(
              (filter: Dictionary, filterIndex: number) => {
                if (filter[1] === config.filter && filter.inherit === true) {
                  dispatch(
                    actionComponentFilterEdit({
                      componentIndex: cIndex,
                      layerIndex: layerIndex,
                      filterIndex: filterIndex,
                      propertyIndex: 2,
                      value: undefined,
                      event: true,
                    })
                  );
                }
              }
            );
          });
        }
      });
      break;
    case 'zoomToFilteredBounds': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }

      const gid = layer?.geometries[layer?.geometryIndex].id;
      let geometry;
      if (window[gid]) {
        geometry = window[gid];
      } else {
        const req = await axios.get(`https://sources.api.akuko.io/v1/geometry/${gid}`);
        window[gid] = req.data;
        geometry = req.data;
      }
      
      const values = event.action.data.value;

      const map =
        window.akukoMaps[
          `${event?.trigger?.componentId || config.componentId || componentId}`
        ];
  
      const features = geometry.features;

      if (!features?.length) {
        return null;
      }
  
      // Extract coordinates and calculate bounds
      const coordinates: [number, number][] = [];
      if (values.length > 0) {
      features.forEach((feature: Dictionary) => {
        if (values.includes(feature.properties[config.filter])) {
          if (feature.geometry.type === 'Polygon') {
            (feature.geometry.coordinates[0] as [number, number][]).forEach(
              (coord) => {
                coordinates.push(coord);
              }
            );
          } else if (feature.geometry.type === 'MultiPolygon') {
            feature.geometry.coordinates.forEach((polygon: Dictionary) => {
              (polygon[0] as [number, number][]).forEach((coord) => {
                coordinates.push(coord);
              });
            });
          }
        }
      });
     } else {
      features.forEach((feature: Dictionary) => {
        if (feature.geometry.type === 'Polygon') {
          (feature.geometry.coordinates[0] as [number, number][]).forEach(
            (coord) => {
              coordinates.push(coord);
            }
          );
        } else if (feature.geometry.type === 'MultiPolygon') {
          feature.geometry.coordinates.forEach((polygon: Dictionary) => {
            (polygon[0] as [number, number][]).forEach((coord) => {
              coordinates.push(coord);
            });
          });
        }
      })
     }

      // Calculate the bounding box of all coordinates
      let bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
      coordinates.forEach((coord) => {
        bounds.extend(coord);
      });

      if (values.length === 0 && coordinates.length > 0) {
        map?.fitBounds(bounds, {
          padding: 20
        });
      } else {
        map?.fitBounds(bounds, {
          padding: config?.padding || 20,
        });
      }

      break;
    }
    case 'zoomToBounds': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      const map =
        window.akukoMaps[
          `${event?.trigger?.componentId || config.componentId || componentId}`
        ];

      if (!event.feature) {
        const featureBounds: string = (await getBounds(
          config.filter,
          event.action?.data.value,
          layer?.source,
          layer?.cube,
          post.sources[layer?.source]?.refresh_key
        )) as string;
        if (featureBounds) {
          // fix for far SE bounds
          const bounds = JSON.parse(featureBounds);
          if (bounds[0] < -177 && bounds[2] > 177 && bounds[3] < 0) {
            bounds[0] = bounds[0] * -1;
          }
          setTimeout(() => {
            map?.fitBounds(bounds, { padding: config.padding });
          }, 100);
        }
      } else {
        if (event.layer) {
          setTimeout(() => {
            map?.fitBounds(JSON.parse(event.feature?.properties?.['_bounds']), {
              padding: config.padding,
            });
          }, 100);
        }
      }
      break;
    }
    case 'flyTo': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      const map = window.akukoMaps[`${config.target}`];
      if (!config?.customLatLng) {
        let center: number[] = [];

        // fly to centroid field
        if (config.centroid && !event.feature) {
          const featureBounds = (await getBounds(
            config.filter,
            event.action?.data.value,
            layer?.source,
            layer?.cube,
            post.sources[layer?.source]?.refresh_key,
            config.centroid
          )) as string;
          if (featureBounds) {
            center = JSON.parse(featureBounds);
          }
        }
        if (
          event.trigger &&
          event.trigger[`${event.trigger.cube}.${config.lng}`]
        ) {
          center = [
            event.trigger[`${event.trigger.cube}.${config.lng}`],
            event.trigger[`${event.trigger.cube}.${config.lat}`],
          ];
        }
        if (
          event?.feature?.properties &&
          event.feature.properties[config.lng]
        ) {
          center = [
            event?.feature?.properties[config.lng],
            event?.feature?.properties[config.lat],
          ];
        }
        if (event.data) {
          const cube = Object.keys(event.data)[0]?.split('.')[0];
          center = [
            Number(event.data[`${cube}.${config.lng}`]),
            Number(event.data[`${cube}.${config.lat}`]),
          ];
        }
        if (!event.trigger && config.lat && config.lng) {
          const getCenter = (await getLatLng(
            config.filter,
            event.action?.data.value,
            layer?.source,
            layer?.cube,
            post.sources[layer?.source]?.refresh_key,
            config.lat,
            config.lng
          )) as number[];
          center = getCenter;
        }

        setTimeout(() => {
          if (Object.keys(center).length) {
            map?.flyTo({
              center: center,
              duration: config.duration || 1000,
              zoom: config.zoom || 2.5,
              bearing: Number(config.bearing) || 0,
              pitch: Number(config.pitch) || 0,
              padding: {
                top: config.paddingTop || 0,
                bottom: config.paddingBottom || 0,
                left: config.paddingLeft || 0,
                right: config.paddingRight || 0,
              },
            });
          }
        }, 100);
      } else if (config.customLng && config.customLat) {
        setTimeout(() => {
          map?.flyTo({
            center: [
              Number(config.customLng) || 0,
              Number(config.customLat) || 0,
            ],
            bearing: Number(config.bearing) || 0,
            pitch: Number(config.pitch) || 0,
            duration: 1000,
            zoom: config.zoom,
            padding: {
              top: config.paddingTop || 0,
              bottom: config.paddingBottom || 0,
              left: config.paddingLeft || 0,
              right: config.paddingRight || 0,
            },
          });
        }, 100);
      }
      break;
    }
    case 'showLayer': {
      let value = true;
      if (event?.trigger?.target?.checked === false) {
        value = false;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'map') {
          c.layers.forEach((l: Dictionary, lIndex: number) => {
            if (config.target?.includes(l.id)) {
              dispatch(
                actionComponentMapLayerVisibleEdit({
                  componentIndex: cIndex,
                  layerIndex: lIndex,
                  property: 'visible',
                  value: value,
                })
              );
            }
          });
        }
      });
      break;
    }
    case 'hideLayer': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'map') {
          c.layers.forEach((l: Dictionary, lIndex: number) => {
            if (config.target?.includes(l.id)) {
              dispatch(
                actionComponentMapLayerVisibleEdit({
                  componentIndex: cIndex,
                  layerIndex: lIndex,
                  property: 'visible',
                  value: false,
                })
              );
            }
          });
        }
      });
      break;
    }
    case 'centerMap': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      const map =
        window.akukoMaps[
          `${event?.trigger?.componentId || config.componentId || componentId}`
        ];
      setTimeout(() => {
        map?.easeTo({
          center: {
            lng: event?.trigger.lngLat.lng,
            lat: event?.trigger.lngLat.lat,
          },
          duration: 1000,
          padding: {
            top: config.paddingTop || 0,
            bottom: config.paddingBottom || 0,
            left: config.paddingLeft || 0,
            right: config.paddingRight || 0,
          },
        });
      }, 100);
      break;
    }
    case 'hideLayout':
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'layout' && config.target.includes(c.id)) {
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: cIndex,
              property: 'hidden',
              value: true,
            })
          );
        }
      });
      break;
    case 'toggleLayout':
      if (event?.trigger?.target?.checked === false) {
        post.components.forEach((c: Dictionary, cIndex: number) => {
          if (c.type === 'layout' && config.target.includes(c.id)) {
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: cIndex,
                property: 'hidden',
                value: true,
              })
            );
          }
        });
      }
      if (event?.trigger?.target?.checked === true) {
        post.components.forEach((c: Dictionary, cIndex: number) => {
          if (c.type === 'layout' && config.target.includes(c.id)) {
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: cIndex,
                property: 'hidden',
                value: false,
              })
            );
          }
        });
      }
      if (event?.trigger?.target?.checked === undefined) {
        post.components.forEach((c: Dictionary, cIndex: number) => {
          if (c.type === 'layout' && config.target?.includes(c.id)) {
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: cIndex,
                property: 'hidden',
                value: c.hidden === true ? false : true,
              })
            );
          }
        });
      }
      break;
    case 'showLayout':
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'layout' && config.target.includes(c.id)) {
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: cIndex,
              property: 'hidden',
              value: false,
            })
          );
        }
      });
      break;
    case 'collapseLayout':
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'layout' && config.target.includes(c.id)) {
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: cIndex,
              property: 'collapsed',
              value: true,
            })
          );
        }
      });
      break;
    case 'uncollapseLayout':
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'layout' && config.target.includes(c.id)) {
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: cIndex,
              property: 'collapsed',
              value: false,
            })
          );
        }
      });
      break;
    case 'highlightCard':
      {
        if (event?.trigger?.target?.checked === false) {
          return null;
        }
        post?.data[config.target]?.forEach(
          (item: Dictionary, index: number) => {
            Object.keys(item).forEach((key: string) => {
              if (key.includes(config.value)) {
                const keyValue = post?.data[config.target][index][key];
                const cardId = post?.data[config.target][index].id;
                if (keyValue === event.feature.properties[config.value]) {
                  const element = document.getElementById(cardId);
                  const elems = document.querySelectorAll('.card');
                  [].forEach.call(elems, (el: Element) => {
                    el.classList.remove('card-highlight');
                  });
                  element?.classList.add('card-highlight');
                  element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }
              }
            });
          }
        );
      }
      break;
    case 'navigateToUrl':
      {
        if (event?.trigger?.target?.checked === false) {
          return null;
        }

        const url = replaceEventTokens(event, config.url);

        if (config.blank) {
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      }
      break;

    case 'updateMapStyle': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'map' && config?.componentId?.includes(c.id)) {
          if (config.target === 'no-style') {
            dispatch(
              actionPostComponentSettingEdit({
                property: 'styleColor',
                componentIndex: cIndex,
                value: config.styleColor || '#000000',
              })
            );
          }
          if (config.target === 'url-style' && config.customStyle) {
            dispatch(
              actionPostComponentSettingEdit({
                property: 'mapStyle',
                componentIndex: cIndex,
                value: config.customStyle,
              })
            );
          }

          dispatch(
            actionPostComponentSettingEdit({
              property: 'styleOption',
              componentIndex: cIndex,
              value: config.target,
            })
          );
        }
      });
      break;
    }

    case 'updateLayerProperty': {
      if (event?.trigger?.target?.checked === false) {
        return null;
      }
      post.components.forEach((c: Dictionary, cIndex: number) => {
        if (c.type === 'map') {
          c.layers.forEach((l: Dictionary, lIndex: number) => {
            if (config.target?.includes(l.id)) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: ['layers'],
                  property: config.property,
                  componentIndex: cIndex,
                  itemIndex: lIndex,
                  value: config.value,
                })
              );
            }
          });
        }
      });
      break;
    }
    case 'webhook':
      {
        if (event?.trigger?.target?.checked === false) {
          return null;
        }

        let body = {};
        if (config.body) {
          body = replaceEventTokens(event, config?.body);
        }
        const url = replaceEventTokens(event, config?.url);
        let messageText = 'Webhook fired';
        if (config?.message) {
          messageText = replaceEventTokens(event, config?.message);
        }

        let headerError = false;
        let headers = {};
        if (config.headers) {
          try {
            headers = JSON.parse(config.headers);
          } catch (err) {
            headerError = true;
          }
        }
        if (headerError) {
          message.error('Invalid webhook headers JSON');
        } else {
          axios({
            method: config.method,
            url: url,
            headers: headers,
            data: body,
            withCredentials: false,
          })
            .then(() => {
              message.success(messageText);
            })
            .catch((err) => {
              message.error(`Webhook error: ${err.message}`);
            });
        }
      }
      break;
  }
};
